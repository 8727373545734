<template>
    <div class="pvhFactory">

        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('Quality feedback standard')}}</div>
            </div>
            <!--data 数据源 v-model：对象行 -->
            <avue-crud :data="data" v-model="obj" :option="setData.option" :page.sync="page"
                       @current-change="currentChange">
                <template slot="menuLeft" style="width: calc(100% - 80px);">
                    <el-row :gutter="20">
                        <!--查询框-->
                        <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                            <el-input v-model.trim="name" placeholder="请输入名称" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;"></el-input>
                            <el-input v-model.trim="code" placeholder="请输入编码" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;"></el-input>
                        </el-col>
                        <!--按钮-->
                        <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4"
                                style=" text-align: right; align-self: flex-end;">
                            <el-button type="primary" icon="el-icon-plus" size="mini" :title="$t('New')" plain
                                       @click="addInfo"> {{$t('New')}}
                            </el-button>
                            <el-button :title="$t('Refresh')" type="primary" icon="el-icon-search" size="mini" plain
                                       @click="refreshChange"> {{$t('Refresh')}}
                            </el-button>
                        </el-col>
                    </el-row>
                </template>
                <template slot-scope="{row,index}" slot="menu">
                    <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('View')"
                               @click="updateInfo(row,index)"></el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {list} from "@/api/feedbackStandard";
    import {feedbacklist} from "@/api/canonicalParameter";

    export default {
        data() {
            return {
                specificStandardsDisabled: true,
                maxValueDisabled: true,
                minValueDisabled: true,
                name: "",
                code: "",
                sType: null,
                obj: {},
                feedbackData: [],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: false,
                    pageSize: 10
                },
                data: [],
            }
        },
        computed: {
            setData() {
                return {
                    option: {
                        page: true,
                        menuTitle: this.$t('OPERATION'),//操作按钮
                        // editTitle: this.$t('Edit'),
                        // addTitle: this.$t('Add'),
                        // menu: false,
                        align: 'center',
                        columnBtn: false,//列冻结、隐藏按钮
                        labelPosition: 'top',
                        simplePage: false,
                        menuAlign: 'center',
                        editBtn: false,
                        delBtn: false,
                        viewBtn: false,
                        viewBtnText: '查看',
                        addBtn: false,      //是否显示添加按钮，默认true
                        refreshBtn: false,      //是否显示刷新按钮，默认true
                        column: [
                            {
                                label: '标准编号',
                                prop: 'code',
                            },
                            {
                                label: '标准名称',
                                prop: 'name',
                            },
                        ],
                    }
                }
            }
        },
        watch: {
            'obj.parameterCode'(val) {
                if (val !== "" && val !== undefined) {
                    var result = this.feedbackData.find(item => item.code === val);
                    if (result != null) {
                        this.sType = result.type;
                        if (result.type === 0) {
                            this.maxValueDisabled = true;
                            this.obj.maxValue = null;
                            this.minValueDisabled = true;
                            this.obj.minValue = null;
                            this.specificStandardsDisabled = false;
                        } else {
                            this.maxValueDisabled = false;
                            this.minValueDisabled = false;
                            this.specificStandardsDisabled = true;
                            this.obj.specificStandards = null;
                        }
                    }
                }
            },
            //监听路由对象是否变化
            $route() {
                this.getList()
            }
        },
        created() {
            const xx = {
                pageNo: 1,
                pageSize: 100,
                total: 100,
            };
            feedbacklist(xx).then(res => {
                this.feedbackData = res.data.data.items;
            });
            this.getList()
        },
        methods: {
            refreshChange() {
                this.getList()
            },
            //新增
            addInfo() {
                this.$router.push({path: '/quality/feedbackStandardAdd', query: {sign: 'add', ID: ''}})
            },
            //详情
            updateInfo(row) {
                this.$router.push({
                    path: '/quality/feedbackStandardAdd',
                    query: {sign: 'update', standardCode: row.code}
                })
            },
            getList() {
                const xx = {
                    pageNo: this.page.currentPage,
                    pageSize: this.page.pageSize,
                    total: this.page.total,
                    code: this.code,
                    name: this.name,
                };
                list(xx).then(res => {
                    if (res.data.data == null) {
                        this.data = [];
                        this.page.total = 0;
                    } else {
                        // debugger
                        this.page.total = res.data.data.total;
                        this.data = res.data.data.items
                    }
                    console.log(res, 'res')
                }).catch(erro => {
                    console.log(erro);
                })
            },
            currentChange(val) {
                this.page.currentPage = val;
                this.getList()
            }
        }
    }
</script>
<style scoped>
</style>
