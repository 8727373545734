var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("Quality feedback standard")))]
            )
          ]),
          _c(
            "avue-crud",
            {
              attrs: {
                data: _vm.data,
                option: _vm.setData.option,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _c("el-button", {
                        attrs: {
                          icon: "el-icon-edit-outline",
                          type: "text",
                          size: "medium",
                          title: _vm.$t("View")
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateInfo(row, index)
                          }
                        }
                      })
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.obj,
                callback: function($$v) {
                  _vm.obj = $$v
                },
                expression: "obj"
              }
            },
            [
              _c(
                "template",
                {
                  staticStyle: { width: "calc(100% - 80px)" },
                  slot: "menuLeft"
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "fromOutDiv",
                          attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "200px !important",
                              marginRight: "5px"
                            },
                            attrs: {
                              placeholder: "请输入名称",
                              clearable: "",
                              size: "small"
                            },
                            model: {
                              value: _vm.name,
                              callback: function($$v) {
                                _vm.name =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "name"
                            }
                          }),
                          _c("el-input", {
                            staticStyle: {
                              width: "200px !important",
                              marginRight: "5px"
                            },
                            attrs: {
                              placeholder: "请输入编码",
                              clearable: "",
                              size: "small"
                            },
                            model: {
                              value: _vm.code,
                              callback: function($$v) {
                                _vm.code =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "align-self": "flex-end"
                          },
                          attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                size: "mini",
                                title: _vm.$t("New"),
                                plain: ""
                              },
                              on: { click: _vm.addInfo }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("New")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                title: _vm.$t("Refresh"),
                                type: "primary",
                                icon: "el-icon-search",
                                size: "mini",
                                plain: ""
                              },
                              on: { click: _vm.refreshChange }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Refresh")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }